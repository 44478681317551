<template>
  <div class="page">
    <div class="headerView">
      <img class="banner" src="../assets/img/agent_headerBg.png">
      <div class="headerContent">
        <div style="font-size:60px;">代理合作全面启动</div>
        <div class="applyBtn pointer" @click="apply">线上申请<img src="../assets/img/agent_arrow_right.png" style="width:16px;height:26px;margin-left:15px;"></div>
        <div style="font-size:20px;font-weight: 400;">咨询电话：4000-519-000转1</div>
      </div>
    </div>
    <div class="contentView">
      <div style="padding:80px 0 80px 0;">
        <div style="font-size: 36px;">代理合作流程</div>
        <div class="agentView">
          <div class="flowView" 
              v-for="(item,index) in agentFlowItems"
              :key="item.title">
              <div>
                <img :src="item.img" style="width:100px;height:100px;margin:0 auto 30px auto;">
                <div>{{item.title}}</div>
              </div>
              <img v-if="(index<agentFlowItems.length-1)"
                 src="../assets/img/agent_flow_line.png"
                 style="width:146px;height:31px;margin:0 20px;"
                 :style="{ transform: index%2?flowLineRotate:''}">
          </div>
        </div>
      </div>
      <div style="padding:80px 0 1px 0;background-color:#F5F5F5;">
        <div style="font-size: 36px;">代理服务支持</div>
        <div class="supportView">
          <div style="width: 300px;margin:40px 0"
              v-for="(item) in supportItems"
              :key="item.title">
            <img :src="item.img" style="width:56px;height:56px;margin:0 auto 20px auto;">
            <div>{{item.title}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      agentFlowItems:[{
        title: '商务合作形成代理商',
        img: require('../assets/img/agent_flow_1.png')
      },{
        title: '全国供应链快速发货配送',
        img: require('../assets/img/agent_flow_2.png')
      },{
        title: '线上便捷上机及管理',
        img: require('../assets/img/agent_flow_3.png')
      },{
        title: '全时段收益实时提取',
        img: require('../assets/img/agent_flow_4.png')
      }],
      flowLineRotate: 'rotate(180deg)',
      supportItems: [{
        title: '代理权益保护',
        img: require('../assets/img/agent_support_1.png')
      },{
        title: '开放共赢升级体系',
        img: require('../assets/img/agent_support_2.png')
      },{
        title: '高效线上运营管理',
        img: require('../assets/img/agent_support_3.png')
      },{
        title: '全国全域城市覆盖',
        img: require('../assets/img/agent_support_4.png')
      },{
        title: '全面系统培训课程',
        img: require('../assets/img/agent_support_5.png')
      },{
        title: '充足稳定货源供应',
        img: require('../assets/img/agent_support_6.png')
      },{
        title: '全天候技术支持',
        img: require('../assets/img/agent_support_7.png')
      },{
        title: '产品品类覆盖各行各业',
        img: require('../assets/img/agent_support_8.png')
      },]
    };
  },
  components: {},
  mounted () {},
  methods: {
    apply(){
      window.open("https://jinshuju.net/f/cZtdMb","_blank")
    }
  }
}
</script>

<style lang='scss' scoped>
.pointer {
  cursor: pointer;
}
.page{
  width: 100%;
  font-weight: 500;
  margin: 0 auto;
  color: #434343;
}
.headerView{
  width: 100%;
  position: relative;
}
.banner {
  width: 100%;
  height: auto;
}
.headerContent{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.applyBtn{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  margin: 50px 0;
  background: linear-gradient(-90deg, #2FAC65 0%, #04BB78 100%);
  border-radius: 10px;
  width: 250px;
  height: 60px;
}
.contentView{
  width: 100%;
  margin: auto;
  font-size: 18px;
}
.agentView{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.flowView{
  display: flex;
  justify-content: center;
  align-items: center;
}
.supportView{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1400px;
  min-width: 1200px;
  margin: 50px auto;
}
</style>